import imageSuccess from '@/assets/svg/alert-success.svg'
import imageError from '@/assets/svg/alert-error.svg'

export const alertCustom = {
    showSuccess: (v, { title, description, titleConfirmButton, showCancelButton }) => {
        return v.$swal.fire({
            width: "369px",
            title: title,
            confirmButtonColor: "#366feb",
            imageUrl: imageSuccess,
            imageWidth: 98,
            imageHeight: 98,
            html: description,
            confirmButtonText: titleConfirmButton,
            showCancelButton: showCancelButton ? true : false,
            cancelButtonText: showCancelButton
        })
    },
    showError: (v, { title, description, titleConfirmButton, showCancelButton }) => {
        return v.$swal.fire({
            width: "369px",
            title: title,
            confirmButtonColor: "#366feb",
            imageUrl: imageError,
            imageWidth: 98,
            imageHeight: 98,
            html: description,
            confirmButtonText: titleConfirmButton,
            showCancelButton: showCancelButton ? true : false,
            cancelButtonText: showCancelButton
        })
    },
    changePasswordSuccess: (v, { title, description, data }) => {
        let buttonText = ""
        data.forEach(e => {
            buttonText += `<div class="col-6">
                        <a href="${e.href}" class="btn-app ${e.icon}">
                            ${e.title}
                        </a>
                    </div>`
        })
        return v.$swal.fire({
            width: "369px",
            title: title,
            confirmButtonColor: "#366feb",
            imageUrl: imageSuccess,
            imageWidth: 98,
            imageHeight: 98,
            html: `<div class="custom">
                    <div class="custom-des">${description}</div>
                    <div class="row custom-form-button">
                        ${buttonText}
                    </div>                
                </div>`,
            showCancelButton: false, // There won't be any cancel button
            showConfirmButton: false // There won't be any confirm button
        })
    },
    popupCenter: ({ url, w, h, close = () => {} }) => {
        // Fixes dual-screen position                             Most browsers      Firefox
        const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
        const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

        const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
        const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

        const systemZoom = width / window.screen.availWidth;
        const left = (width - w) / 2 / systemZoom + dualScreenLeft
        const top = (height - h) / 2 / systemZoom + dualScreenTop
        const newWindow = window.open(url, 'OAUTH2', `scrollbars=yes, width=${w}, height=${h}, top=${top}, left=${left}`)

        if (window.focus) newWindow.focus();

        let timer = setInterval(function() {
            if (newWindow.closed) {
                clearInterval(timer);
                close()
            }
        }, 200);
    }
}