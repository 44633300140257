import AuthApi from '@/state/api/auth_api'
import router from '@/router'
import Validate from '@/common/validate'

export const state = {
  auth: JSON.parse(decodeURIComponent(window.localStorageX && window.localStorageX.getItem('_auth') || '{}')),
  cacheScreen: JSON.parse(window.localStorageX && window.localStorageX.getItem('cache_screen') || '{}'),
  returnUrl: window.localStorageX ? window.localStorageX.getItem('return_url') : null,
};

export const mutations = {
  CACHE_SCREEN: (_, data) => {
    window.localStorageX.setItem('cache_screen', JSON.stringify(data || {}))
  },
  RETURN_URL: (_, data) => {
    window.localStorageX.setItem('return_url', data);
  },
  AUTH: (_, data) => {
    window.localStorageX.setItem('_auth',encodeURIComponent(JSON.stringify(data || {})))
  }
};


function navigator(_, data){
  if (data._s) {
    _.commit('CACHE_SCREEN', data)
    return router.push({
      name: data._s,
      params: data
    });
  }

  if (data.user) {
    _.commit('AUTH', data)
    if(sessionStorage.getItem('meeyid_ref')){
      return location.href = '/userbackend/apps/affiliate-marketing'
    }else{
      location.href = '/'
      return;
    }
  }

  return data
}

export const actions = {
  async verifyEmail(_, {token}){
    return await AuthApi.verifyEmail({ token })
  },
  async login(_, {target, password}){
    Validate.validate({
      target: {
        label: 'Tài khoản',
        value: target,
        rule: 'required',
      },
      password: {
        label: 'Mật khẩu',
        value: password,
        rule: 'required',
      },
    });

    if (!Validate.isValid()) {
      throw Validate.error
    }
    return await AuthApi.login({ target , password }).then(resp => {
      const { data } = resp
      return navigator(_, data)
    })

  },
  async loginWithPhone(_, { phone }) {
    if(!phone || phone == undefined || phone == ''){
      throw {
        error: {
          validates: [
            {
              key: 'phone',
              message: 'Vui lòng nhập số điện thoại'
            }
          ]
        }
      }
    }

    Validate.validate({
        phone: {
          label: "Số điện thoại",
          value: phone,
          rule: "required",
          func: (val) => /^0[0-9]{9}$/.test(val),
        },
      },
      true
    );

    if (!Validate.isValid()) {
      throw Validate.error;
    }
    return await AuthApi.loginWithPhone({ phone }).then(resp => {
      const { data } = resp
      return navigator(_, data)
    })
  },
  confirmPassword(_, { password, code }) {
    Validate.validate({
        password: {
          label: "Mật khẩu",
          value: password,
          rule: "required",
        },
      },
      true
    );

    if (!Validate.isValid()) {
      throw Validate.error;
    }

    return AuthApi.confirmPassword({ password, code }).then(rs => {
      const { data } = rs
      return navigator(_, data)
    })
  },

  forgotPassword(_, { target }) {
    Validate.validate({
        target: {
          label: "Số điện thoại/ Email",
          value: target,
          rule: "required",
        },
      },
      true
    );

    if (!Validate.isValid()) {
      throw Validate.error;
    }
    return AuthApi.forgotPassword({ target }).then(rs => {
      const { data } = rs
      return navigator(_, data)
    })
  },
  changePassword(_, { target }) {
    return AuthApi.changePassword({ target }).then(rs => {
      const { data } = rs
      return navigator(_, data)
    })
  },

  confirmOTP(_, { requestId, phone, phoneCode, otp }) {
    Validate.validate({
        otp: {
          label: "Mã xác nhận",
          value: otp,
          rule: "required",
        },
      },
      true
    );

    if (!Validate.isValid()) {
      throw Validate.error;
    }

    return AuthApi.confirmOTP({ requestId, phone, phoneCode, otp }).then(rs => {
      const { data } = rs
      return navigator(_, data)
    })
  },

  resendOTP(_, { requestId, phone, phoneCode }) {
    return AuthApi.resendOTP({ requestId, phone, phoneCode }).then(rs => {
      const { data } = rs
      return navigator(_, data)
    })
  },

  setupPassword(_, { password, code }) {

    Validate.validate({
      password: {
        label: 'Mật khẩu',
        value: password,
        rule: 'required|range:8,32',
      },
    });

    if (!Validate.isValid()) {
      throw Validate.error
    }

    return AuthApi.setupPassword({ password, code }).then(rs => {
      const { data } = rs
      return navigator(_, data)
    })
  },
  register(_, { name, email, password, confirm_password, code, refCode, refSource, channelType }) {
    Validate.validate({
      name: {
        label: 'Họ & Tên',
        value: name,
        rule: 'required|max:100|min:5',
        // func: (val) => /[a-zA-Z \p{L}]$/.test(val)  // cho nhập full
      },
      email: {
        label: 'Email',
        value: email,
        rule: 'required|max:100',
        func: (val) => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(val)
      },
      password: {
        label: 'Mật khẩu',
        value: password,
        rule: 'required|range:8,32',
      },
      confirm_password: {
        label: 'Xác nhận mật khẩu',
        value: confirm_password,
        rule: 'required|same:password'
      }
    });

    if (!Validate.isValid()) {
      throw Validate.error
    }

    return AuthApi.register({ name, email, password, code, refCode, refSource, channelType }).then(rs => {
      const { data, error } = rs
      if(error.status){
        sessionStorage.removeItem('refCode')
        sessionStorage.removeItem('refSource')
        sessionStorage.removeItem('channelType')
      }
      return navigator(_, data)
    })
  },

  loginWithProvider(_, { mode, code }) {
    return AuthApi.loginWithProvider({ mode, code }).then(resp => {
      const { data } = resp
      return navigator(_, data)
    })

  },
  confirmForgotPassword(_, { token }) {
    return AuthApi.confirmForgotPassword({ token }).then(resp => {
      const { data } = resp
      if (data._s) {
        _.commit('CACHE_SCREEN', data)
        return router.push({
          name: data._s
        })
      }
      return data
    })
  },
  confirmPhone(_, { code, phone, phoneCode }) {
    if(!phone || phone == undefined || phone == ''){
      throw {
        error: {
          validates: [
            {
              key: 'phone',
              message: 'Vui lòng nhập số điện thoại'
            }
          ]
        }
      }
    }
    Validate.validate({
      phone: {
        label: 'Số điện thoại',
        value: phone,
        rule: 'required',
        func: (val) => /^0[0-9]{9}$/.test(val)
      },
    });

    if(!Validate.isValid()){
      throw Validate.error
    }

    return AuthApi.confirmPhone({ code, phone, phoneCode }).then(resp => {
      const { data } = resp
      return navigator(_, data)
    })
  }
}