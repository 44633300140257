<template>
  <div class="board" @click.prevent="closeClick()">
    <div class="alert-container">
      <div class="close" v-if="forceClose" @click.prevent="closeClick()">
        <img src="@/assets/icons/close.svg">
      </div>

      <img
        class="logo"
        src="@/assets/svg/alert-success.svg"
        v-if="type == 'success'"
      />
      <img class="logo" src="@/assets/svg/alert-error.svg" v-else />
      <div style="height: 0px" class="form-group"></div>
      <div class="title">
        <span>{{ title }}</span>
      </div>

      <div class="form-group message">
        {{ message }}
      </div>
      <div style="height: 0px" class="form-group"></div>
      <div class="form-group">
        <button class="btn btn-primary" @click="primaryClick">
          {{ primaryText || "Đóng" }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["title", "message", "forceClose", "primaryText"],
  data() {
    return {};
  },

  created() {},
  methods: {
    closeClick() {
      if(this.forceClose){
        this.$emit("actionClose");
      }
    },
    primaryClick() {
      this.$emit("actionPrimary");
    },
  },
};
</script>
<style scoped lang='scss'>
.board {
  position: fixed;
  background: rgb(0 0 0 / 20%);
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.alert-container {
  width: 400px;
  padding: 40px;
  text-align: center;
  margin: auto;
  background: white;
  border-radius: 8px;
  position: relative;

  .close {
    position: absolute;
    right: 25px;
    top: 25px;
    width: 24px;
    height: 24px;
    display: flex;
  }

  .title {
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 10px;
    display: flex;

    .action {
      display: flex;
      width: 30px;
    }

    span {
      width: 100%;
      flex: 1;
    }
  }

  .logo {
    width: 60px;
  }

  .message {
    text-align: center;
    color: #626262;
    font-size: 20px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 450px) {
  .board {
  position: fixed;
  background: rgb(0 0 0 / 20%);
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.alert-container {
  width: 90%;

  padding: 20px;
  text-align: center;
  margin: auto;
  background: white;
  border-radius: 8px;
  position: relative;

  .close {
    position: absolute;
    right: 25px;
    top: 25px;
    width: 20px;
    height: 20px;
    display: flex;
  }

  .title {
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 36px;
    margin-bottom: 10px;
    display: flex;

    .action {
      display: flex;
      width: 30px;
    }

    span {
      width: 100%;
      flex: 1;
    }
  }

  .logo {
    width: 60px;
  }

  .message {
    text-align: center;
    color: #626262;
    font-size: 16px;
    margin-bottom: 10px;
  }
}
}
</style>