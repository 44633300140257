// rule: 'required|numeric|max:10|min:0|same:target|range:2,4',
function validateWithString(data, rule, value) {
  const rules = rule.split('|');
  for (let key of rules) {
    let validObj;
    switch (key) {
     
      case 'required':
        validObj = isRequired(value);
        break;
        
      case 'numeric':
        validObj = isNumeric(value);
        break;
      default:
        if (key.startsWith('range:')) {
          const data = key.split(':')[1];
          const ranges = data.split(',')
          validObj = isRange(rule, value, ranges)
          break;
        }

        if (key.startsWith('max:')) {
          const maxs = key.split(':');
          if (maxs.length == 2) {
            validObj = isMax(rule, value, maxs[1]);
          }
          break;
        }

        if (key.startsWith('min:')) {
          const mins = key.split(':');

          if (mins.length == 2) {
            validObj = isMin(rule, value, mins[1]);
          }
          break;
        }

        if (key.startsWith('same:')) {
          const samese = key.split(':');
          if (samese.length == 2) {
            validObj = isSame(data, value, samese[1]);
          }
          break;
        }

        validObj = {
          valid: false,
          message: `${key} not support`,
        }
        break;
    }

    if (!validObj.valid) return validObj;
  }

  return {
    valid: true
  }
}

function isRange(rule, value, [min, max]){
  if (rule.indexOf('numeric') > -1) {
    // là kiểu số
    if(value < min || value > max){
      return {
        valid: false,
        message: `phải nằm trong khoảng từ ${min} đến ${max}`
      }
    }
    return {
      valid: true
    }
  }

  const lengthText = value.length
  if(lengthText < min || lengthText > max){
    return {
      valid: false,
      message: `phải có từ ${min} đến ${max} ký tự`
    }
  }

  return {
    valid: true
  }
}
function isSame(data, value, target) {
  if (target in data) {
    if (value == data[target].value) {
      return {
        valid: true
      }
    } else {
      return {
        valid: false,
        message: `không khớp với ${data[target].label.toLowerCase()}`
      }
    }
  }
  return {
    valid: false,
    message: `lỗi cú pháp`
  }
}

function isMax(rule, valueX, target) {
  const targetConvert = Number.parseInt(target);
  if (targetConvert.toString() == 'NaN') {
    console.error(`Lỗi biến đầu vào: max:${target}. Giá trị của max phải là số`)
    return { valid: false, message: 'lỗi rule' };
  }

  target = targetConvert;
  if (rule.indexOf('numeric') > -1) {
    let { valid, value, message } = isNumeric(valueX);
    if (!valid || value > target) {
      return {
        valid: false,
        message: message || `phải nhỏ hơn hoặc bằng ${target}`
      }
    }
    return {
      valid: true,
    }
  }

  //string
  if (valueX && valueX.length <= target) {
    return {
      valid: true,
    }
  }

  return {
    valid: false,
    message: `phải nhỏ hơn hoặc bằng ${target} ký tự`
  }
}

function isMin(rule, valueX, target) {
  const targetConvert = Number.parseInt(target);
  if (targetConvert.toString() == 'NaN') {
    console.error(`Lỗi biến đầu vào: min:${target}. Giá trị của min phải là số`)
    return { valid: false, message: 'lỗi rule' };
  }
  target = targetConvert;

  if (rule.indexOf('numeric') > -1) {
    let { valid, value, message } = isNumeric(valueX);

    if (!valid || value < target) {
      return {
        valid: false,
        message: message || `phải lớn hơn hoặc bằng ${target}`
      }
    }
    return {
      valid: true,
    }
  }

  //string
  if (valueX && valueX.length >= target) {
    return {
      valid: true,
    }
  }

  return {
    valid: false,
    message: `phải lớn hơn hoặc bằng ${target} ký tự`
  }

}

function isRequired(value) {
  if (!value || value == undefined || value == '') {
    return {
      valid: false,
      message: 'không được để trống'
    }
  }
  return {
    valid: true
  }
}

function isNumeric(value) {
  const val = new Number(value);
  if (val.toString() == 'NaN') {
    return {
      valid: false,
      message: 'phải là số',
      value: val
    }
  }
  return {
    valid: true,
    value: val
  }
}


class Validate {
  constructor() {
    this.messages = []
  }

  validate(data, stopIsError = false) {
    this.messages = [];
    for (const [key, val] of Object.entries(data)) {
      let { rule, value, label, func, custom } = val
      if (rule && typeof rule == 'string') {
        const { valid, message } = validateWithString(data, rule, value);
        if (!valid) {
          this.messages.push({
            key: key,
            message: `${label || key} ${custom || message}`
          })
          if (stopIsError)
            break;

          continue; // k cần check tiếp
        }
      }

      if (func && typeof func == 'function') {
        if (!func(value)) {
          this.messages.push({
            key: key,
            message: `${label || key} không đúng định dạng`
          })
          if (stopIsError)
            break;

          continue; // k cần check tiếp
        }
      }
    }
  }

  isValid() {
    return this.messages.length == 0;
  }

  get error()  {
    if (this.isValid()) return null
    return {
      error: {
        message: 'Input error',
        validates: this.messages
      }
    }
  }
}

module.exports = new Validate;