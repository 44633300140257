require('./bootstrap');
import Vue from 'vue'
import App from './App.vue'

// Vue Router
import router from './router/index'

// Axios
// Lodash
// Vuex Store
import store from './state/store'

// import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// Import Bootstrap an BootstrapVue CSS files (order is important)
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
// Styles: CSS
// VueToastr
// import VueToastr from 'vue-toastr';
// VeeValidate
// Moment
import Moment from 'vue-moment';
// Sweetalert2
// import VueSweetalert2 from 'vue-sweetalert2';
// import '@/assets/scss/app.scss'
// import '@/assets/scss/app.tablet.scss'
// import '@/assets/scss/app.mobile.scss'
import '@/assets/scss/sdk.scss'

// Vue.use(BootstrapVue)
// Vue.use(IconsPlugin)

// Vue.use(VueToastr, {});

// Vue.use('ValidationProvider', ValidationProvider);

Vue.use(Moment);

// Vue.use(VueSweetalert2);

Vue.config.productionTip = false
Vue.config.silent = true
import { alertCustom } from '@/common/func.js'

Vue.prototype.$func = alertCustom

import Bus from "@/common/bus";
Vue.use(Bus)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');