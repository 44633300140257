// import Cookie from 'js-cookie'

let token = null
// if (Cookie.get('_auth')) {
  // token = JSON.parse(Cookie.get('_auth'))['access_token']
// }
const state = {
  isLoading: false,
  alertData: null,
  accessToken: token,
  browserId: window.localStorageX ? window.localStorageX.getItem('ping') : null
};

const getters = {
  browserId(state){
    if(!state.browserId){
      let id = `${navigator.userAgent.replace(/\D+/g, "")}_${Date.now()}`;
      if (window.localStorageX) {
        window.localStorageX.setItem('ping', id)
      }
      return id
    }
    return state.browserId

  }
}

const mutations = {
  IS_LOADING: (state, val) => {
    state.isLoading = val;
  },
  SET_TOKEN: (state, token) => {
    state.accessToken = token
  },
  ALERT_DATA: (state, alertData) => {
    state.alertData = alertData
  },
};

const actions = {
  loading(_, isLoading = true) {
    _.commit('IS_LOADING', isLoading);
  },

  showAlert(_, alertData) {
    window.$('body').addClass('no-scroll')
    _.commit('ALERT_DATA', {
      actionPrimary: () => {
        _.commit('ALERT_DATA', null)
        window.$('body').removeClass('no-scroll')

      },
      actionClose: () => {
        _.commit('ALERT_DATA', null)
        window.$('body').removeClass('no-scroll')

      },
      ...alertData
    });
  },

  hideAlert(_) {
    window.$('body').removeClass('no-scroll')
    _.commit('ALERT_DATA', null);
  },

  async validateAll(_, self) {
    const result = await self.$validator.validateAll();
    if (!result) {
      window.$("input, textarea, select").each(function () {
        let input = window.$(this);

        if (input.attr("aria-invalid") && input.attr("aria-invalid") === "true") {
          if (input.is(':hidden')) {
            input = input.parent();
          }
          window.$("html,body").animate({
            scrollTop: input.offset().top - 300
          }, "slow");
          return false;
        }
      });
    }
    return result;

  }
};

export default {
  state,
  getters,
  mutations,
  actions
}