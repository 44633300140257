// require('fontsource-montserrat')
window.localStorageX = localStorage;

import axios from "./axios"
import lodash from 'lodash'

require('./common/extensions');
require('./common/constant');

window._ = lodash
window.$ = require('jquery')

window.axios = axios
