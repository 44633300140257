import Vue from 'vue';
import Vuex from 'vuex';
// import createPersistedState from 'vuex-persistedstate';

import moduleGlobal from './module/global'
import modules from './module'

delete modules.global

Vue.use(Vuex);
export default new Vuex.Store({
  modules: modules,
  getters: moduleGlobal.getters,
  state: moduleGlobal.state,
  mutations: moduleGlobal.mutations,
  actions: moduleGlobal.actions,
  // plugins: [createPersistedState()],
  strict: process.env.NODE_ENV !== 'production',
});
