import Cookie from 'js-cookie'
import UserApi from '../state/api/user_api'

const authWithCacheScreen = (from, to, next) => {
  const cacheScreen = JSON.parse(window.localStorageX.getItem("cache_screen") || "{}");
  if (cacheScreen && from.name == cacheScreen._s) {
    next();
  } else {
    next({
      name: "login-with-phone",
    });
  }
};

const isGuest = async (f, t, next) => {
  let _auth = JSON.parse(
    decodeURIComponent(window.localStorageX.getItem("_auth") || "{}")
  );
  let match = document.cookie.match(RegExp("(?:^|;\\s*)" + escape('_auth') + "=([^;]*)"));
  if (match && match[1] && (!_auth || Object.keys(_auth).length === 0)) {
    _auth = JSON.parse(decodeURIComponent(match[1]));
  }

  if (_auth && _auth.access_token && _auth.user) {
   
    try{
       // verify accessToken
      await UserApi.me()
      document.cookie = `token=${_auth.access_token};max-age=${_auth.expires_in};path=/`
      const clientRedirect = Cookie.get('client');
      if(clientRedirect){
        const client = JSON.parse(atob(clientRedirect))
        // login kieu redirect
        const queryString = Object.keys(client).map(key => key + '=' + encodeURIComponent(client[key])).join('&')
        location.href = `/sdk/v4/authorize?${queryString}`
        Cookie.remove('client')
        return
      }
      location.href = '/'
    }catch(e){
      document.cookie = `_auth=;max-age=0`;
      document.cookie = `token=;max-age=0`;
      window.localStorageX.removeItem("_auth");
      location.href = '/';
    }
  } else {
    document.cookie = `_auth=;max-age=0`;
    document.cookie = `token=;max-age=0`;
    next();
  }
};
const isAuth = async (f, t, next) => {
  let _auth = JSON.parse(
    decodeURIComponent(window.localStorageX.getItem("_auth") || "{}")
  );
  let match = document.cookie.match(RegExp("(?:^|;\\s*)" + escape('_auth') + "=([^;]*)"));
  if (match && match[1] && (!_auth || Object.keys(_auth).length === 0)) {
    _auth = JSON.parse(decodeURIComponent(match[1]));
  }

  if (_auth && _auth.access_token && _auth.user) {
    try {
      // verify accessToken
      await UserApi.me()
      document.cookie = `token=${_auth.access_token};max-age=${_auth.expires_in};path=/`
      const clientRedirect = Cookie.get('client');
      if (clientRedirect) {
        const client = JSON.parse(atob(clientRedirect))
        // login kieu redirect
        const queryString = Object.keys(client).map(key => key + '=' + encodeURIComponent(client[key])).join('&')
        location.href = `/sdk/v4/authorize?${queryString}`
        Cookie.remove('client')
        return false;
      }
      // save cookie
      if( ['', '/'].includes(location.pathname)){
        location.href = '/userbackend/users/user-info'
      }else{
        next()
      }
      return true;
    } catch (e) {
      window.localStorageX.removeItem("_auth")
      document.cookie = `_auth=;max-age=0`;
      document.cookie = `token=;max-age=0`;
      next({
        name: "login-with-phone",
      });
    }
  } else {
    document.cookie = `_auth=;max-age=0`
    document.cookie = `token=;max-age=0`;
    next({
      name: "login-with-phone",
    });
  }
  return false;
};

export default [
  {
    path: "/logout",
    name: "logout",
    component: () => import('@/views/pages_new/Logout')
  },
  {
    path: "/verify-email",
    name: "verify-email",
    component: () => import("@/views/pages_new/auth/VerifyEmail"),
  },
  {
    path: "/verify-email-success",
    component: () => import("@/views/layouts/Default"),
    children: [{
      path: '',
      name: "verify-email-success",
      component: () => import("@/views/pages_new/auth/VerifyEmailSuccess"),
    }],
   
  },
  {
    path: "/verify-email-expired",
    component: () => import("@/views/layouts/Default"),
    children: [{
      path: '',
      name: "verify-email-expired",
      component: () => import("@/views/pages_new/auth/VerifyEmailExpired"),
    }],
   
  },
  {
    path: "",
    name: "under",
    beforeEnter: isAuth
    // component: () => import("@/views/layouts/User"),
    // children: [
    //   {
    //     path: "",
    //     name: "home",
    //     component: () => import("@/views/pages_new/user/Home"),
    //   },
    // ],
  },
   // xoa di khi release UserProfile 
   {
    path: "/",
    name: "home",
    beforeEnter: isAuth
    // component: () => import("@/views/layouts/User"),
    // children: [
    //   {
    //     path: "",
    //     name: "home",
    //     component: () => import("@/views/pages_new/user/Home"),
    //   },
    // ],
  },
  // =============================================================================
  // FULL PAGE LAYOUT
  // =============================================================================
  {
    path: "/login",
    component: () => import("@/views/layouts/Sdk"),
    beforeEnter: isGuest,
    name: "login-with-phone",
    // children: [
    //   {
    //     path: "login",
    //     name: "login-with-phone",
    //     component: () => import("@/views/pages_new/auth/LoginWithPhone"),
    //   },
    //   {
    //     path: "basic-login",
    //     name: "basic-login",
    //     component: () => import("@/views/pages_new/auth/Login"),
    //   },
    //   {
    //     path: "confirm-otp",
    //     name: "confirm-otp",
    //     beforeEnter: authWithCacheScreen,
    //     component: () => import("@/views/pages_new/auth/ConfirmOTP"),
    //   },
    //
    //   {
    //     path: "confirm-password",
    //     name: "confirm-password",
    //     beforeEnter: authWithCacheScreen,
    //
    //     component: () => import("@/views/pages_new/auth/ConfirmPassword"),
    //   },
    //   {
    //     path: "confirm-phone",
    //     name: "confirm-phone",
    //     beforeEnter: authWithCacheScreen,
    //     component: () => import("@/views/pages_new/auth/ConfirmPhone"),
    //   },
    //   {
    //     path: "register",
    //     name: "register",
    //     beforeEnter: authWithCacheScreen,
    //     component: () => import("@/views/pages_new/auth/Register"),
    //   },
    //
    //   {
    //     path: "forgot-password",
    //     name: "forgot-password",
    //     component: () => import("@/views/pages_new/auth/ForgotPassword"),
    //   },
    //   {
    //     path: "confirm-forgot-password",
    //     name: "confirm-forgot-password",
    //     beforeEnter: authWithCacheScreen,
    //     component: () => import("@/views/pages_new/auth/ConfirmForgotPassword"),
    //   },
    // ],
  },
  {
    path: "/",
    component: () => import("@/views/layouts/Default"),
    children: [
      {
        path: "setup-password",
        name: "setup-password",
        beforeEnter: authWithCacheScreen,
        component: () => import("@/views/pages_new/auth/SetupPassword"),
      },
      {
        path: "reset-password",
        name: "reset-password",
        beforeEnter: (f, t, next) => {
          const urlSearchParams = new URLSearchParams(window.location.search);
          const params = Object.fromEntries(urlSearchParams.entries());
          if (params.token) {
            next();
          } else {
            next("home");
          }
        },
        component: () => import("@/views/pages_new/auth/ResetPassword"),
      },
      {
        path: 'change-password-success',
        name: 'change-password-success',
        // beforeEnter: authWithCacheScreen,
        component: () => import('@/views/pages_new/auth/ChangePasswordSuccess'),
      },
    ]
  },
  // Redirect to 404 page, if no match found
  {
    path: "*",
    redirect: "/login",
  },
];
