import axios from 'axios'
import store from '@/state/store'
import Cookie from 'js-cookie'


// create an axios instance
let service = axios.create({
    baseURL: process.env.VUE_APP_MEEYID_API,

    timeout: 15000, // request timeou
    timeoutErrorMessage: 'Lỗi kết nối. Vui lòng kiểm tra lại đường truyền.',
    headers: {
        'accept-language': 'vi'
    }
})

function getClientId(){
    // ddo
    try{
        const clientCookie = Cookie.get('client');
        if(clientCookie){
            const client = JSON.parse(atob(clientCookie))
            return client.client_id
        }
    }catch(e){
        console.log(e);
        Cookie.remove('client');
    }
    return process.env.VUE_APP_CLIENT_ID
}

function getAffilateId(){
    // ddo
    try{
        const clientCookie = Cookie.get('client');
        if(clientCookie){
            const client = JSON.parse(atob(clientCookie))
            return client.affilate_id
        }
    }catch(e){
        console.log(e);
    }
    return null
}

// request interceptor
service.interceptors.request.use(
    config => {
        if (store.getters['user/accessToken']) {
            config.headers['Authorization'] = `Bearer ${store.getters['user/accessToken']}`
        }
        config.headers['x-browser-id'] = `${store.getters['browserId']}`
        config.headers['x-client-id'] = getClientId()
        config.headers['x-affilate-id'] = getAffilateId()
        return config
    },
    error => {
        // do something with request error
        return Promise.reject(error)
    }
)

// // response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
        const res = response.data
        if (res.error && !res.error.status) {
            return Promise.reject(res)
        }
        return res
    },
    error => {
        if (error.response) {
            return Promise.reject(error.response.data)
        }
        return Promise.reject(error)
    }
)

export default service