export default {
    verifyEmail({token}){
        return window.axios.request({
            method: "post",
            // url: `${process.env.VUE_APP_URL}/api/verify-email`,
            url: `${process.env.VUE_APP_MEEYID_API}/user/v4/verify-email`,
            data: JSON.stringify({
                token
            }),
            headers: {
                'accept-language': 'vi',
                'Content-Type': 'application/json'
            }
        })
    },
    forgotPassword(payload) {
        return window.axios.post('/auth/v4/forgot-password', payload)
    },
    confirmOTP(payload) {
        return window.axios.post('/auth/v4/confirm-otp', payload)
    },
    setupPassword(payload) {
        return window.axios.post('/auth/v4/setup-password', payload)
    },
    confirmForgotPassword(payload) {
        return window.axios.post('/auth/v4/confirm-forgot-password', payload)
    },
    loginWithPhone(payload) {
        return window.axios.post('/auth/v4/login-with-phone', payload)
    },
    confirmPassword(payload) {
        return window.axios.post('/auth/v4/confirm-password', payload)
    },
    register(payload) {
        return window.axios.post('/auth/v4/register', payload)
    },
    login(payload) { //login email vs password
        return window.axios.post('/auth/v4/login', payload)
    },
    logout(payload) {
        return window.axios.delete('/user/v4/logout', { refresh_token: payload.refeshToken })
    },
    confirmPhone(payload) {
        return window.axios.post('/auth/v4/confirm-phone', payload)
    },
    refreshToken(payload) {
        return window.axios.post('/auth/v4/refresh-token', { refresh_token: payload.refeshToken })
    },
    changePassword(payload) {
        return window.axios.post('/user/v4/change-password', payload)
    },
    resendOTP(payload) {
        return window.axios.post('/auth/v4/resend-otp', payload)
    },
    loginWithProvider(payload) {
        return window.axios.post(`/auth/v4/oauth2/${payload.mode}`, { token: payload.code })
    },
}