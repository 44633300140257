import LocationApi from '@/state/api/location_api'

export const state = {}

export const mutations = {}

export const getters = {}

export const actions = {
    getLocation(ctx, payload) {
        return LocationApi.get(payload).then(rs => {
            const { data } = rs
            return Promise.resolve(data)
        })
    }
}