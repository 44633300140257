window.getCookieByName = function (name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
};

window.removeCookieByName = function (name) {
  if (window.getCookieByName(name)) {
    document.cookie = name + "=;Max-Age=-99999999;";
  }
};

window.cloneJSONObject = function (object) {
  try {
    return JSON.parse(JSON.stringify(object));

  } catch (e) {
    return {};
  }
}

window.initAuthSdk = (w, d, s, t) => {
  const h = d.getElementsByTagName(s)[0],
    j = d.createElement(s);
  j.async = true;
  j.src = "https://meeyid-api.meey.dev/sdk/js/web.min.js";
  j.type = "text/javascript";
  h.parentNode.insertBefore(j, h);
  const mc = d.createElement(t)
  d.body.appendChild(mc)
}