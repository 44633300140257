import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import routes from './routes'

Vue.use(VueRouter);
Vue.use(VueMeta, {
    keyName: 'page',
});

const router = new VueRouter({
    routes,
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return {
                x: 0,
                y: 0
            }
        }
    },
});

router.beforeEach((to, from, next) => {
    // if (to.meta.authRequired) {
    //     if (!(jwt.isAuthenticated() || Cookie.get('_auth'))) {
    //         store.dispatch("auth/setReturnUrl", to.query.returnUrl);
    //         router.push({ name: 'login', query: { to: to.path } })
    //     }
    // } else {
    //     if ((jwt.isAuthenticated() && Cookie.get('_auth'))) {
    //         router.push({ path: '/user' })
    //     }
    // }
    next()
})

export default router