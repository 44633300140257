import UserApi from "@/state/api/user_api";
import Validate from "@/common/validate";

export const state = {
  auth: JSON.parse(decodeURIComponent(window.localStorageX && window.localStorageX.getItem("_auth") || "{}")),
};

export const mutations = {
  UPDATE_PROFILE(state, val) {
    state.auth.user = val;  
    window.localStorageX.setItem(
      "_auth",
      encodeURIComponent(JSON.stringify(state.auth || {}))
    );
  },
  UPDATE_AVATAR(state, avatar){
    state.auth.user.avatar = avatar;  
    window.localStorageX.setItem(
      "_auth",
      encodeURIComponent(JSON.stringify(state.auth || {}))
    );
  },
  LOGOUT: () => {
    window.localStorageX.removeItem("_auth");
  },
  AUTH: (_, data) => {
    window.localStorageX.setItem(
      "_auth",
      encodeURIComponent(JSON.stringify(data || {}))
    );
  },
};

export const getters = {
  user(state) {
    return state.auth.user;
  },
  accessToken() {
    let _auth = JSON.parse(
      decodeURIComponent(window.localStorageX.getItem("_auth") || "{}")
    );
    let match = document.cookie.match(RegExp("(?:^|;\\s*)" + escape('_auth') + "=([^;]*)"));
    if (match && match[1] && (!_auth || Object.keys(_auth).length === 0)) {
      _auth = JSON.parse(decodeURIComponent(match[1]));
    }

    return _auth && _auth.access_token || null;
  },
  refreshToken(state) {
    return state.auth.refresh_token;
  },

  avatar({ profile }) {
    return profile.avatar;
  },
  basicInfo({ profile }) {
    return {
      name: profile.name,
      sex: profile.sex,
      dob: profile.dob, //yyyy-mm-dd
      address: profile.address,
      detailAddress: profile.detailAddress || {
        city: {
          _id: null,
          name: null,
        },
        district: {
          _id: null,
          name: null,
        },
        ward: {
          _id: null,
          name: null,
        },
      },
      identityCard: profile.identityCard,
      objectType: profile.objectType,
      company: profile.company,
      website: profile.website,
      introduce: profile.introduce,
    };
  },
  basicContacts({ profile }) {
    return {
      emailVerified: profile.emailVerified,
      phoneVerified: profile.phoneVerified,
      email: profile.email,
      phone: profile.phone,
    };
  },
};
export const actions = {
  saveProfile({ commit }, val) {
    commit("UPDATE_PROFILE", val);
  },
  updateProfile({ commit }, val) {
    Validate.validate(
      {
        name: {
          label: "Họ và tên",
          value: val.name,
          rule: "required|max:100|min:5",
        },
        address: {
          label: "Địa chỉ",
          value: val.address,
          rule: "max:255",
        },
        dob: {
          label: "Ngày sinh",
          value: val.dob,
        },
        sex: {
          label: "Giới tính",
          value: val.sex,
        },
      },
      true
    );

    if (!Validate.isValid()) {
      throw Validate.error;
    }
    return UserApi.update(val).then((rs) => {
      if (rs && rs.data) {
        commit("UPDATE_PROFILE", rs.data);
      }
      console.log("xong", rs);
      return Promise.resolve(rs);
    });
  },
  getProfile({ commit }) {
    return UserApi.me().then((rs) => {
      if (rs && rs.data) {
        commit("UPDATE_PROFILE", rs.data);
      }
      return Promise.resolve(rs);
    }).catch(e => {
      if(e.error && e.error.code == '401'){
        commit("LOGOUT");
      }
    });
  },
  verifyEmail(_, payload) {
    // success => dispatch('getProfile')
    return UserApi.verifyEmail(payload).then((rs) => {
      console.log("rs :>> ", rs);
      return Promise.resolve(rs);
    });
  },
  uploadAvatar(_, avatar) {
    return UserApi.uploadAvatar(avatar).then((rs) => {
      return Promise.resolve(rs);
    }).then(resp => {
      _.commit('UPDATE_AVATAR', resp.updates.avatar)
    });
  },
  preload(_, payload) {
    return UserApi.preload(payload).then((rs) => {
      console.log("rs :>> ", rs);
      return Promise.resolve(rs);
    });
  },
  logout(_, payload) {
    console.log(payload);
    return UserApi.logout(payload)
      .then((rs) => {
        _.commit("LOGOUT");
        return Promise.resolve(rs);
      })
      .catch((e) => {
        if (e.error) {
          if (e.error.code == "401") {
            _.commit("LOGOUT");
          }
        }
        return Promise.reject(e);
      });
  },
  changePassword(_, { password, new_password }) {
    Validate.validate({
      password: {
        label: "Mật khẩu",
        value: password,
        rule: "required|range:8,32",
      },
      new_password: {
        label: "Mật khẩu mới",
        value: new_password,
        rule: "required|range:8,32",
      },
    });

    if (!Validate.isValid()) {
      throw Validate.error;
    }

    return UserApi.changePassword({ password, new_password }).then((rs) => {
      const { data } = rs;
      return data;
    });
  },
  getRefName(_, payload) {
    return UserApi.getUserByRefCode(payload).then((rs) => {
      return Promise.resolve(rs);
    });
  },
};
