

Array.prototype.firstWhere = function (cb) {
  for (const item of this) {
    if (cb(item)) {
      return item;
    }
  }
  return null;
};

Array.prototype.firstIndexWhere = function (cb) {
  for (let i = 0; i < this.length; i++) {
    if (cb(this[i])) {
      return {
        index: i,
        value: this[i]
      }
    }
  }
  return {index : 0, value: null};
};

Array.prototype.move = function (from, to) {
  if (to >= this.length) {
    var k = to - this.length + 1;
    while (k--) {
      this.push(undefined);
    }
  }
  this.splice(to, 0, this.splice(from, 1)[0]);
  return this; // for testing
};

Array.prototype.exists = function (cb) {
  for (const item of this) {
    if (cb(item)) {
      return true;
    }
  }
  return false;
};


Array.prototype.findWhere = function (cb) {
  const ret = [];
  for (const item of this) {
    if (cb(item)) {
      ret.push(item);
    }
  }
  return ret;
};

Array.prototype.deleteWhere = function (cb) {
  for (const [index, item] of this.entries()) {
    if (cb(item)) {
      this.splice(index, 1);
    }
  }
};

String.prototype.getParameterByName = function (name) {
  name = name.replace(/[\[\]]/g, '\\$&'); // eslint-disable-line
  let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(this);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

String.prototype.truncate = function (length = 5, separator = '...') {
  if (this.length <= length) return this;

  separator = separator || '...';

  const sepLen = separator.length,
    charsToShow = length - sepLen,
    frontChars = Math.ceil(charsToShow / 2),
    backChars = Math.floor(charsToShow / 2);

  return this.substr(0, frontChars) + separator + this.substr(this.length - backChars);
};

// Object.prototype.has = function(key){
//   return Object.keys(this).indexOf(key) !== -1
// }

