<template>
  <div id="app" style="display: -webkit-box">
    <RouterView />
    <div v-if="isLoading" class="loading">
      <div class="lds-dual-ring" style="width: 70px; height: 70px"></div>
    </div>

    <alert-view
      v-if="alertData"
      :type="alertData.type"
      :title="alertData.title"
      :message="alertData.message"
      :primaryText="alertData.primaryText"
      :forceClose="alertData.forceClose || false"
      @actionPrimary="alertData.actionPrimary"
      @actionClose="alertData.actionClose"
    >
    </alert-view>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import AlertView from "./views/components/AlertView.vue";

export default {
  components: { AlertView },
  name: "app",
  page: {},
  data() {
    return {
      eventSource: null,
      keepAliveTimer: null,
      id: null,
      user: window.localStorageX.getItem("_auth"),
      watchAuthLocalStorage: null,
    };
  },
  created() {
    if (!this.testCoockies()) {
      alert("no data access");
    }
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.referralAfi) {
      sessionStorage.setItem("meeyid_ref", params.referralAfi);
      let refAfi = Buffer.from(
        decodeURIComponent(params.referralAfi),
        "base64"
      ).toString("ascii");
      refAfi = refAfi ? JSON.parse(refAfi) : {};

      if (refAfi.refCode) {
        sessionStorage.setItem("refCode", refAfi.refCode);
      }
      if (refAfi.refSource) {
        sessionStorage.setItem("refSource", refAfi.refSource);
      }
      if (refAfi.channelType) {
        sessionStorage.setItem("channelType", refAfi.channelType);
      }
    }
  },
  beforeDestroy() {
    if (this.eventSource) this.eventSource.close();
    if (this.keepAliveTimer) clearTimeout(this.keepAliveTimer);
  },
  computed: {
    ...mapGetters(["browserId"]),
    ...mapState({
      isLoading: (state) => state.isLoading,
      alertData: (state) => state.alertData,
    }),
  },
  methods: {
    checkAuth() {
      // check data
      const current = window.localStorageX.getItem("_auth");
      if ((!current && this.user) || (!this.user && current)) {
        //conflic data
        location.reload();
      }
    },

    testCoockies() {
      var enabled = false;
      if (navigator.cookieEnabled) {
        enabled = true;
      }
      document.cookie = "testcookie=1";
      enabled = document.cookie.indexOf("testcookie=") != -1;
      document.cookie = "testcookie=1; expires=Thu, 01-Jan-1970 00:00:01 GMT";
      return enabled;
    },

    connectEvent() {
      this.gotActivity();
      this.eventSource = new EventSource(
        `${process.env.VUE_APP_MEEYID_API}/events?id=${this.browserId}`
      );
      this.eventSource.onmessage = (event) => {
        const parsedData = JSON.parse(event.data);
        if (parsedData.type == "onSignInCompleted") {
          location.reload();
          this.gotActivity();
        } else if (parsedData.type == "onSignOutCompleted") {
          location.reload();
          this.gotActivity();
        }
      };
    },
    gotActivity() {
      if (this.keepAliveTimer) clearTimeout(this.keepAliveTimer);

      this.keepAliveTimer = setTimeout(() => {
        if (this.eventSource) this.eventSource.close();
        this.connectEvent();
      }, 30 * 1000);
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep {
  .loading {
    position: fixed;
    display: flex;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999999;
    width: 100%;
    height: 100%;
    background: rgba(237, 237, 237, 0.53);
    overflow: hidden;
  }
  .loading > img {
    margin: auto;
  }
}
</style>
