let baseURL =process.env.VUE_APP_URL;
export default {
  update(payload) {
    return window.axios.put("/user/v4/update", payload);
  },
  me() {
    return window.axios.get("/user/v4/me");
  },
  verifyEmail(payload) {
    return window.axios.put("/user/v4/verify-email", payload);
  },
  uploadAvatar(avatar)
  {
    let headers = {
      "Content-Type": "multipart/form-data",
      Cookie: document.cookie
    }
    var fd = new FormData();
    fd.append('avatar', avatar);

    return window.axios.request({
      method: "post",
      url: `${baseURL}/api/me/avatar`,
      data: fd,
      headers: headers,
    })
  },

  preload(payload)
  {
    return  window.axios.get(`${baseURL}/api/preload`, payload);
  },
  logout(payload) {
    return window.axios.delete("/user/v4/logout", payload);
  },
  changePassword(payload) {
    return window.axios.post("/user/v4/change-password", payload);
  },
  getUserByRefCode(payload) {
    return window.axios.get("/user/v4/user-by-ref-code/" + payload.refCode);
  },
};
